import React from "react"
import DownloadAppScreen from "../components/DownloadAppScreen/DownloadAppScreen"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"
const PublicGroup: React.FC = () => {
  return (
    <main className="w-full bg-lightBackground h-screen">
      <FaviconHeader />
      <DownloadAppScreen />
    </main>
  )
}

export default PublicGroup
